import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import MainLayout from './MainLayout.vue';
import HomePage from './HomePage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', component: HomePage}
    ]
});

createApp(MainLayout)
    .use(router)
    .mount('#app');
