<template>
  <div class="container">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'MainLayout'
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow: auto;
  background-color: #D3D3D3;
  box-sizing: border-box;
}
</style>
