<template>
  <div class="container">
    <h1 class="title">A History of Glasnevin</h1>

    <div class="image-container" @click="redirectToFlowpaperLink">
      <img src="./assets/cover.png" alt="A History of Glasnevin cover" class="cover"/>
      <h2 class="click-instructions-button">Read A History of Glasnevin</h2>
    </div>

    <p class="paragraph">
      This book tells the story of a village and its people
      over the centuries, beginning as far back as the sixth
      century, when St. Mobhi founded a monastery and
      monastic school on the hill overlooking the Tolka
      River. After the coming of the Normans, the lands of
      Glasnevin were granted to the Priory of the Holy Trinity
      attached to Christ Church, and a manorial village
      replaced the older settlement.
    </p>
    <p class="paragraph">
      In the eighteenth century. Glasnevin became a fashionable
      area for the prosperous classes and Dublin’s
      literary circle, its most famous resident at that time
      being Doctor Patrick Delany, friend of Dean Swift.
      In the nineteenth century, many institutions, mainly
      educational, were established in the area, as well as
      the Botanic Gardens and Ireland’s largest and most
      famous cemetery. As a result, for the remainder of the
      century, Glasnevin retained its “pastoral nature” and
      it was spared the extensive housing that took place
      over Dublin’s south-side.
    </p>
    <p class="paragraph">
      In 1901, Glasnevin became incorporated in Dublin
      City and developed into a modern “red-brick” suburb.
      The next seventy years saw the gradual renewal
      of the village, with the demise of so many of its quaint
      old and historic buildings. It is hoped that this book
      will enlighten the people of Glasnevin and preserve its
      story for future generations.
    </p>
    <p class="paragraph italic">
      Tony O’Doherty has lived in Glasnevin for practically all of his life.
      An amateur historian, he has compiled this history, the fruits of research,
      observation and local lore over many decades.
    </p>
    <p class="paragraph italic">
      He was educated in St. Patricks National School Drumcondra, Belvedere
      College, and subsequently, UCD where he obtained a degree in engineering.
      He worked with ESB in the design of power stations, and he became a
      Chartered Engineer and a European Engineer (Eur. Ing.) He has worked as
      a consultant to the Electro-Technical Council of Ireland since his retirement.
    </p>

    <h3 class="contact-button">
      <a href="#" @click.prevent="toggleContactOverlay" class="contact-link">Contact</a>
    </h3>

    <transition name="fade">
      <div class="contact-overlay" v-if="showContactOverlay">
        <div class="overlay-content">
          <h3 class="contact-header">Contact</h3>
          <div class="terms-container">
            <p>
              Please note this contact email is only for technical or other website related queries.
              <br>
              I cannot answer any questions relating to the content of the book or about obtaining physical copies.
            </p>
            <label for="agreeTerms" class="terms-label">
              <input type="checkbox" id="agreeTerms" v-model="termsAgreed">
              I agree - show contact email
            </label>
            <p v-if="termsAgreed">
              <a href="mailto:historyofglasnevin@post.com" target="_blank">
                historyofglasnevin@post.com
              </a>
            </p>
          </div>
          <button @click="toggleContactOverlay" class="close-button">Close</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>export default {
  data() {
    return {
      showContactOverlay: false,
      termsAgreed: false,
    };
  },
  methods: {
    redirectToFlowpaperLink() {
      window.location.href = 'https://77f3073d.flowpaper.com/HistoryofGlasnevin/';
    },
    toggleContactOverlay() {
      this.showContactOverlay = !this.showContactOverlay;
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:wght@700&display=swap');

.container {
  padding-bottom: 20px;
}

.image-container {
  cursor: pointer;
}

.title {
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
  color: #891A1C;
  font-family: 'Gentium Book Plus', serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.image-container {
  margin-top: 3vh;
  margin-bottom: 1vh;
}

.cover {
  max-height: 60vh;
  max-width: 100%;
  width: auto;
  transition: transform 0.3s ease-in-out;
  object-fit: contain;
  display: block;
  margin: auto;
}

.image-container {
  position: relative;
}

.paragraph {
  font-size: 0.85vw;
  width: 40%;
  margin: 1vh auto;
}

.italic {
  font-style: italic;
}

.click-instructions-button {
  display: inline-block;
  background-color: #891A1C;
  color: white;
  padding: 10px 20px;
  margin-top: 4vh;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
  transition: background-color 0.3s;
  text-decoration: none;
  font-size: 1vw;
}

.click-instructions-button:hover {
  background-color: #6a1517;
}

.contact-button {
  display: inline-block;
  background-color: grey;
  color: white;
  padding: 10px 20px;
  margin-top: 2vh;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
  transition: background-color 0.3s;
  text-decoration: none;
  font-size: 0.75vw;
}

.contact-button:hover {
  text-decoration: underline;
}

.contact-link {
  color: white;
  text-decoration: none;
}

.contact-button:hover .contact-link {
  text-decoration: underline;
}

.contact-link:active {
  color: white;
}

.contact-header {
  font-size: 2vh;
}

.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.terms-container {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 10vw 20px 10vw;
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 1.6;
  font-size: 0.8vw;
  text-align: center;
}

.terms-container::-webkit-scrollbar {
  width: 10px;
}

.terms-container::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 10px;
}

.terms-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

.terms-container::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}

.terms-label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.close-button {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

.overlay-content {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 9vw;
  }

  .paragraph {
    font-size: 4vw;
    width: 80%;
    margin: 1vh auto;
  }

  .cover {
    max-height: 50vh;
  }

  .contact-button {
    font-size: 3vw;
  }

  .click-instructions-button {
    font-size: 3.5vw;
  }

  .contact-header {
    font-size: 5.5vw;
  }

  .terms-container {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .title {
    font-size: 5vw;
  }

  .paragraph {
    font-size: 2.5vw;
    width: 75%;
    margin: 1vh auto;
  }

  .cover {
    max-height: 50vh;
  }

  .contact-button {
    font-size: 1vw;
  }

  .click-instructions-button {
    font-size: 2.5vw;
  }

  .contact-header {
    font-size: 4vw;
  }

  .terms-container {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .title {
    font-size: 5vw;
  }

  .paragraph {
    font-size: 2vw;
    width: 70%;
    margin: 1vh auto;
  }

  .cover {
    max-height: 50vh;
  }

  .contact-button {
    font-size: 1.25vw;
  }

  .click-instructions-button {
    font-size: 2.5vw;
  }

  .contact-header {
    font-size: 3vw;
  }

  .terms-container {
    font-size: 1.75vw;
  }
}

@media screen and (min-width: 1401px) and (max-width: 2048px) {
  .title {
    font-size: 5vw;
  }

  .paragraph {
    font-size: 1.25vw;
    width: 55%;
    margin: 1vh auto;
  }

  .cover {
    max-height: 50vh;
  }

  .contact-button {
    font-size: 1vw;
  }

  .click-instructions-button {
    font-size: 2vw;
  }

  .contact-header {
    font-size: 2vw;
  }

  .terms-container {
    font-size: 1vw;
  }
}
</style>
